<template>
    <div class="app-container">
        <div id="problemsContainer">
            <Box :type="'error'" :active="true" v-if="inputProblems.length > 0">
                <div slot="box-body">
                    <span class="title-no-border">
                        <strong>{{ $t('products.pendingproduct.delete') }}:</strong>
                    </span>
                    <ul class="rawUl">
                        <li v-for="problem in inputProblems" :key="problem">
                            <span v-html="problem"></span>
                        </li>
                    </ul>
                </div>
            </Box>
        </div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>Optiuni</span>
            </div>
            <div class="body">
                <div class="sub-title">Adaugare prefix cod produs</div>
                <el-checkbox
                    :checked="!!options.forceInsert"
                    v-model="options.forceInsert"
                    label="Permite adaugarea de prefix la codul produsului pentru produsele ce exista pe site dar au categorie si producator diferit"
                    border
                ></el-checkbox>
            </div>
        </el-card>
        <div class="separator"></div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('products.pendingproduct.edit-header') }}</span>
                <span style="float: right">
                    <el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="saveProduct">
                        {{ $t('products.pendingproduct.save') }}</el-button
                    >
                </span>
            </div>
            <div class="productDetails">
                <div class="title">
                    {{ $t('products.pendingproduct.category-title') }}
                </div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.category') }}</div>
                    <c-select
                        @selected="categorySelected"
                        v-if="typeof product.categories_id !== 'undefined'"
                        :categ="product.categories_id"
                    ></c-select>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.name') }}</div>
                    <el-input
                        placeholder="Ex: Telefon Mobil Samsung Galaxy S8 G950F 64GB 4G Black"
                        v-model="product.products_name"
                        show-word-limit
                        :maxlength="characterLimits.productName"
                    ></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.brand') }}</div>
                    <el-input placeholder="Ex: Samsung" v-model="product.manufacturers_name"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.product-model') }}</div>
                    <el-input placeholder="Ex: SM-G950FZKAROM" v-model="product.products_model" disabled></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.product.intern-id') }}</div>
                    <el-input placeholder="Ex: 123456" v-model="product.vendor_ext_id"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.price') }}</div>
                    <el-input placeholder="Ex: 101.3" v-model="product.pretf"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.old-price') }}</div>
                    <el-input placeholder="Ex: 129" v-model="product.pret_vechi"></el-input>
                </div>
                <div class="separator"></div>
                <div v-if="product.virtual == 0">
                    <div class="sub-title">{{ $t('products.pendingproduct.dimensiuni_cm') }}</div>
                    <el-row :gutter="20">
                        <el-col :xs="24" :md="8">
                            <div class="sub-title">{{ $t('products.pendingproduct.dimensiuni_latime') }}</div>
                            <el-input-number
                                :controls="false"
                                class="delinput"
                                placeholder="Ex: 21"
                                v-model="product.products_dimensions.latime"
                            />
                        </el-col>
                        <el-col :xs="24" :md="8">
                            <div class="sub-title">{{ $t('products.pendingproduct.dimensiuni_inaltime') }}</div>
                            <el-input-number
                                placeholder="Ex: 11"
                                :controls="false"
                                class="delinput"
                                v-model="product.products_dimensions.inaltime"
                            />
                        </el-col>
                        <el-col :xs="24" :md="8">
                            <div class="sub-title">{{ $t('products.pendingproduct.dimensiuni_adancime') }}</div>
                            <el-input-number
                                placeholder="Ex: 5"
                                :controls="false"
                                class="delinput"
                                v-model="product.products_dimensions.adancime"
                            />
                        </el-col>
                    </el-row>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.warranty-pf') }}</div>
                    <el-input placeholder="Ex: 24" v-model="product.garantie"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.warranty-pj') }}</div>
                    <el-input placeholder="Ex: 12" v-model="product.garantie_pj"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.stock') }}</div>
                    <el-input placeholder="Ex: 10" v-model="product.stoc"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">Status</div>
                    <el-select v-model="product.status" placeholder="Select" class="fullWidth">
                        <el-option :label="$t('products.pendingproduct.active')" value="1"> </el-option>
                        <el-option :label="$t('products.pendingproduct.inactive')" value="0"> </el-option>
                    </el-select>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.process-time') }}</div>
                    <el-input
                        :placeholder="$t('products.pendingproduct.process-time-ph')"
                        v-model="product.procesare_in"
                    ></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('products.pendingproduct.real_weight') }}</div>
                    <el-input v-model="product.real_weight"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">
                        {{ $t('products.pendingproduct.virtual') }} {{ $t('products.pendingproduct.virtual_extra') }}
                    </div>
                    <el-select v-model="product.virtual" placeholder="Select" class="fullWidth">
                        <el-option :label="$t('products.pendingproduct.is_virtual')" value="1"> </el-option>
                        <el-option :label="$t('products.pendingproduct.is_not_virtual')" value="0"> </el-option>
                    </el-select>
                </div>
            </div>
            <div class="separator"></div>
            <div class="productFamily">
                <div class="title">
                    {{ $t('products.pendingproduct.family') }}
                </div>
                <template v-if="allCharacteristics.length > 0">
                    <!--					<div>-->
                    <!--						<div class="sub-title">{{$t('products.pendingproduct.family-id')}}</div>-->
                    <!--						<el-input :placeholder="$t('products.pendingproduct.family-id')" v-model="product.family_id">-->
                    <!--						</el-input>-->
                    <!--					</div>-->
                    <!--					<div class="separator"></div>-->
                    <!--					<div>-->
                    <!--						<div class="sub-title">{{$t('products.pendingproduct.family-name')}}</div>-->
                    <!--						<el-input :placeholder="$t('products.pendingproduct.family-name')"-->
                    <!--							v-model="product.family_name"></el-input>-->
                    <!--					</div>-->
                    <div>
                        <el-select v-model="product.family_id" @change="onFamilySelect">
                            <el-option
                                v-for="family in families"
                                :key="family.id"
                                :value="family.id"
                                :label="family.name"
                            >
                            </el-option>
                        </el-select>
                        <el-button
                            v-if="product.family_id != null && product.family_id > 0"
                            type="danger"
                            @click="removeProductFamily"
                            >Scoate produsul din familia curenta
                        </el-button>
                        <el-button type="primary" @click="getFamilies">Reincarca lista familii </el-button>
                        <el-button type="success" @click="addNewFamily">Adauga familie noua </el-button>
                    </div>
                    <div class="separator"></div>
                    <div class="sub-title" v-if="product.family_id != null && product.family_id > 0">
                        <strong>{{ $t('products.pendingproduct.family-charact') }}</strong>
                        <el-button type="primary" icon="el-icon-plus" size="mini" @click="addFamilyCharact" plain>
                        </el-button>
                    </div>
                    <el-card shadow="never" class="product-family-card" v-for="(item, i) in familyCharacts" :key="i">
                        <div slot="header" class="clearfix">
                            <span class="sub-title">{{ $t('products.pendingproduct.type') }} #{{ i + 1 }}</span>
                            <el-button style="float: right" size="small" type="text" @click="removeFamilyCharact(i)">
                                {{ $t('general.delete') }}</el-button
                            >
                        </div>
                        <div>
                            <div class="sub-title">{{ $t('products.pendingproduct.family-type') }}</div>
                            <el-select
                                size="small"
                                filterable
                                v-model="familyCharacts[i].charact"
                                :placeholder="$t('products.pendingproduct.family-type')"
                                :no-data-text="$t('products.pendingproduct.no-data-text')"
                                class="fullWidth"
                            >
                                <el-option
                                    v-for="item in familyTypeOptions"
                                    :label="item.label"
                                    :value="item.value"
                                    :key="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="separator"></div>
                        <div>
                            <div class="sub-title">{{ $t('products.pendingproduct.family-mode') }}</div>
                            <el-select
                                size="small"
                                v-model="familyCharacts[i].mode"
                                placeholder="Modul familiei"
                                class="fullWidth"
                            >
                                <el-option
                                    v-for="item in familyModeOptions"
                                    :label="item.label"
                                    :value="item.value"
                                    :key="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="separator"></div>
                    </el-card>
                </template>
                <template v-else>
                    <div class="sub-title">
                        <strong>{{ $t('products.pendingproduct.select-category') }}</strong>
                    </div>
                </template>
            </div>
            <div class="separator"></div>
            <div class="productCharacts">
                <div class="title">
                    {{ $t('products.pendingproduct.characts') }}
                </div>
                <div
                    v-if="
                        typeof product.categories_id !== 'undefined' &&
                        product.categories_id > 0 &&
                        allCharacteristics.length > 0
                    "
                >
                    <div v-for="(charact, x) in allCharacteristics" :key="x">
                        <div class="sub-title">
                            {{ charact.charact_name }}
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('products.pendingproduct.tooltip-1')"
                                placement="top-start"
                            >
                                <el-button
                                    type="primary"
                                    size="mini"
                                    icon="el-icon-plus"
                                    plain
                                    @click.native="updateCount(x)"
                                ></el-button>
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('products.pendingproduct.tooltip-2')"
                                placement="top-start"
                            >
                                <el-button
                                    type="primary"
                                    size="mini"
                                    icon="el-icon-minus"
                                    plain
                                    @click.native="removeCharactValue(x)"
                                ></el-button>
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('products.pendingproduct.tooltip-3')"
                                placement="top-start"
                                v-if="parseInt(charact.restrictions) !== 1 && charact.charact_value !== null"
                            >
                                <el-button
                                    style="margin-left: 0"
                                    type="warning"
                                    size="mini"
                                    icon="el-icon-tickets"
                                    plain
                                    @click.native="openModal(charact.charact_id, charact.restrictions)"
                                ></el-button>
                            </el-tooltip>
                        </div>
                        <div v-if="charact.charact_value === null">
                            <el-input
                                v-for="i in charact.count"
                                :key="i"
                                @change="setCharactVal(i, charact['selected_' + i], charact)"
                                :placeholder="setPlaceholder(charact.priority)"
                                :class="checkCharactPriority(charact.charact_id, charact.priority)"
                                class="margin-bottom-10"
                                v-model="charact['selected_' + i]"
                                show-word-limit
                                :maxlength="characterLimits.characteristics"
                            ></el-input>
                        </div>
                        <div v-else>
                            <span v-for="i in charact.count" :key="i">
                                <el-select
                                    filterable
                                    v-model="charact['selected_' + i]"
                                    @change="setCharactVal(i, charact['selected_' + i], charact)"
                                    class="fullWidth margin-bottom-10"
                                    clearable
                                    :placeholder="setPlaceholder(charact.priority)"
                                    :class="checkCharactPriority(charact.charact_id, charact.priority)"
                                >
                                    <el-option v-for="val in charact.charact_value" :value="val" :key="val">
                                    </el-option>
                                </el-select>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-else class="sub-title">
                    <strong>{{ $t('products.pendingproduct.select-category-2') }}</strong>
                </div>
            </div>
            <div class="separator"></div>
            <div class="productDescription">
                <div class="title">
                    {{ $t('products.pendingproduct.desc') }}
                </div>
                <Box :type="'info'" :active="true">
                    <div slot="box-body">
                        <ul class="rawUl">
                            <li>Evitati linkurile externe in descriere. Acestea pot fi eliminate automat.</li>
                            <li>Tipuri de imagini acceptate: JPG, PNG, GIF.</li>
                        </ul>
                    </div>
                </Box>
                <Editor :text.sync="product.products_description"></Editor>
            </div>
            <div class="separator"></div>
            <div class="productImages">
                <div class="title">
                    {{ $t('products.pendingproduct.images') }}
                </div>
                <Box :type="'info'" :active="true">
                    <div slot="box-body">
                        <ul class="rawUl">
                            <li>Tipuri de imagini acceptate: JPG, PNG, GIF.</li>
                            <li>Dimensiune maxima acceptata: 1200x1200px</li>
                            <li>Dimensiune minima acceptata: 200x200px</li>
                            <li>Imaginile vor fi reprocesate automat si vor fi transformate in format JPG.</li>
                        </ul>
                    </div>
                </Box>
                <div class="mainImage">
                    <div class="sub-title">
                        {{ $t('products.pendingproduct.main-image') }}
                    </div>
                    <img :src="mainImageLink" alt="" />
                    <el-input v-model="product.products_image" size="mini" class="input-with-select secInput">
                        <el-upload
                            :id="`primary_upload`"
                            accept="image/*"
                            size="mini"
                            slot="append"
                            :auto-upload="false"
                            :show-file-list="false"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :on-change="replacePrincipalImage"
                        >
                            <el-button slot="trigger" type="primary"><i class="el-icon-upload"></i></el-button>
                        </el-upload>
                    </el-input>
                </div>

                <!--<div class="secImages">-->
                <!--<div class="sub-title">-->
                <!--{{$t('products.pendingproduct.sec-image')}}-->
                <!--</div>-->
                <!--<div class="secImage" v-for="(img, i) in secondaryImages" :key="i">-->
                <!--<img :src="img.url" alt="">-->
                <!--<el-input v-model="secondaryImages[i].url" size="mini" class="input-with-select secInput">-->
                <!--<el-button v-if="typeof img.url !== 'undefined' && img.url !== ''" slot="append" icon="el-icon-picture" :title="$t('products.pendingproduct.set-main')"-->
                <!--@click="setPrimaryImage(i)"></el-button>-->
                <!--</el-input>-->
                <!--</div>-->
                <!--</div>-->
                <div class="secImages">
                    <div class="sub-title">
                        {{ $t('products.pendingproduct.sec-image') }}
                    </div>
                    <div class="secImage" v-for="(img, i) in secondaryImages" :key="i">
                        <img :src="img.url" alt="" />
                        <el-input v-model="secondaryImages[i].url" size="mini" class="input-with-select secInput">
                            <el-button
                                v-if="typeof img.url !== 'undefined' && img.url !== ''"
                                slot="prepend"
                                icon="el-icon-picture"
                                :title="$t('products.product.set-main')"
                                @click="setPrimaryImage(i)"
                            ></el-button>
                            <el-upload
                                :id="`file_${i}`"
                                accept="image/*"
                                size="mini"
                                slot="append"
                                :auto-upload="false"
                                :show-file-list="false"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :on-change="replaceImage"
                            >
                                <el-button slot="trigger" type="primary" @click="setID(i)"
                                    ><i class="el-icon-upload"></i
                                ></el-button>
                            </el-upload>
                        </el-input>
                    </div>
                </div>
            </div>
        </el-card>
        <div class="separator"></div>
        <el-card class="box-card">
            <el-button type="primary" style="width: 100%" icon="el-icon-check" plain @click.native="saveProduct">
                {{ $t('products.pendingproduct.save') }}</el-button
            >
        </el-card>

        <el-dialog :title="$t('products.pendingproduct.add-value')" :visible.sync="dialogVisible" class="dialog-xs">
            <span>
                <div class="sub-title">{{ $t('products.pendingproduct.value') }}</div>
                <el-input v-model="newValueInput"></el-input>
            </span>

            <Box v-if="modalCharactRestriction == 2" :type="'info'" :active="true" style="margin: 10px 0">
                <div slot="box-body">
                    <ul class="rawUl">
                        <li>Valorile noi necesita aprobare.</li>
                        <li>Valorile noi vor fi asociate produsului dupa aprobare.</li>
                    </ul>
                </div>
            </Box>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{ $t('general.cancel') }}</el-button>
                <el-button type="primary" @click="addNewCharactVal">{{ $t('general.add') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const Box = () => import('vue-info-box-element');
const Editor = () => import('@/components/Editor');
const CategorySelect = () => import('@/components/CategoriesSelect');
import { getCategCharacts } from '@/api/publicare';
import { getWaitingProduct, updateWaitingProduct, ajaxUploadImage, getProductsFamilyForCategory } from '@/api/produse';
import { charactPriority, setParams, debugPromise } from '@/utils/index';
import { EventBus } from '@/utils/Bus.js';

import { Message } from 'element-ui';

import isEmpty from 'lodash.isempty';
import has from 'lodash.has';
import isUndefined from 'lodash.isundefined';
import findIndex from 'lodash.findindex';
import size from 'lodash.size';
import isNull from 'lodash.isnull';

export default {
    components: {
        Box,
        Editor,
        'c-select': CategorySelect,
    },
    props: ['pid'],
    data() {
        return {
            waitingId: '',
            categories: [],
            rawCategories: [],
            product: {
                products_dimensions: {},
            },
            dialogVisible: false,
            newValueInput: '',
            modalCharactId: '',
            modalCharactRestriction: '',
            clone: {},
            config: {
                btns: [
                    ['viewHTML'],
                    ['undo', 'redo'], // Only supported in Blink browsers
                    ['fontsize', 'formatting'],
                    ['foreColor', 'colors', 'strong', 'em', 'del'],
                    ['superscript', 'subscript'],
                    ['link'],
                    ['insertImage'],
                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                    ['unorderedList', 'orderedList'],
                    ['horizontalRule'],
                    ['removeformat'],
                    ['fullscreen'],
                ],
            },
            allCharacteristics: [],
            requiredCharacteristics: [],
            secondaryImages: [],
            inputProblems: [],
            options: {
                forceInsert: false,
            },
            familyModeOptions: [
                {
                    value: 1,
                    label: 'Text',
                },
                {
                    value: 2,
                    label: this.$t('products.pendingproduct.image'),
                },
            ],
            familyCharacts: [
                // 	{
                // 	charact: '',
                // 	type: 1
                // }
            ],
            idSecondaryImages: 0,
            families: [],
            chosenFamily: '',
        };
    },
    methods: {
        setID(index) {
            this.idSecondaryImages = index;
        },
        replaceImage(data) {
            const params = setParams({});
            params.append('image', data.raw);
            ajaxUploadImage(params)
                .then((res) => {
                    this.$set(this.secondaryImages, this.idSecondaryImages, {
                        id: this.secondaryImages[this.idSecondaryImages].id,
                        url: res.message[0],
                    });
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'ajaxUploadImage',
                            params,
                        },
                    });
                    console.log(e);
                });
        },
        replacePrincipalImage(data) {
            const params = setParams({});
            params.append('image', data.raw);
            ajaxUploadImage(params)
                .then((res) => {
                    this.product.products_image = res.message[0];
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'ajaxUploadImage',
                            params,
                        },
                    });
                    console.log(e);
                });
        },
        checkCharactPriority(charId, priority) {
            priority = parseInt(priority);
            switch (true) {
                // case priority > 19:
                //     return {
                //         'is-danger': true
                //     };
                //     break;
                default:
                    return {
                        'is-success': false,
                    };
            }
        },
        setPlaceholder(priority) {
            priority = parseInt(priority);
            switch (true) {
                // case priority > charactPriority():
                //     return this.$t('products.pendingproduct.required-charact');
                //     break;
                default:
                    return this.$t('products.pendingproduct.optional-charact');
            }
        },
        removeCharactValue(indexAll) {
            const indexVal = this.allCharacteristics[indexAll].count;
            if ('undefined' != this.allCharacteristics[indexAll]['selected_' + indexVal]) {
                delete this.allCharacteristics[indexAll]['selected_' + indexVal];
            }

            if (this.allCharacteristics[indexAll].count > 0) {
                const count = this.allCharacteristics[indexAll].count - 1;
                this.$set(this.allCharacteristics[indexAll], 'count', count);
            }
            this.$forceUpdate();
        },
        updateCount(index) {
            const count = this.allCharacteristics[index].count + 1;
            this.$set(this.allCharacteristics[index], 'count', count);
            this.$forceUpdate();
        },
        openModal(charactId, restriction) {
            this.modalCharactId = charactId;
            this.modalCharactRestriction = restriction;
            this.dialogVisible = true;
        },
        getCategCharacts(val) {
            if (typeof val === 'undefined') return false;
            this.allCharacteristics = [];
            this.requiredCharacteristics = [];
            getCategCharacts({
                categ_id: val,
            })
                .then((res) => {
                    this.allCharacteristics = Object.values(res.message).sort((a, b) => {
                        return b.priority - a.priority;
                    });
                    // Adding the required cahracteristics to array
                    for (const x in this.allCharacteristics) {
                        this.$set(this.allCharacteristics[x], 'count', 1);
                        if (typeof this.product.charact[this.allCharacteristics[x].charact_id] === 'undefined') {
                            this.$set(this.product.charact, this.allCharacteristics[x].charact_id, {});
                        } else {
                            this.allCharacteristics[x].count =
                                this.product.charact[this.allCharacteristics[x].charact_id].length || 1;
                            for (const y in this.product.charact[this.allCharacteristics[x].charact_id]) {
                                const inc = parseInt(y) + 1;
                                this.$set(
                                    this.allCharacteristics[x],
                                    'selected_' + inc,
                                    this.product.charact[this.allCharacteristics[x].charact_id][y]
                                );
                                // this.allCharacteristics[x]['selected_' + inc] = this.product.charact[this.allCharacteristics[x].charact_id][y]
                            }
                        }
                        if (this.allCharacteristics[x].priority > charactPriority()) {
                            this.requiredCharacteristics.push(this.allCharacteristics[x].charact_id);
                        }
                    }
                })
                .then(() => {
                    return this.getFamilies();
                })
                .then(() => {
                    if (this.product.family_id != null && !isNaN(parseInt(this.product.family_id))) {
                        const productFamily = this.families.find(
                            (fam) => parseInt(fam.id) === parseInt(this.product.family_id)
                        );
                        if (productFamily == null) {
                            this.sbMsg({
                                type: 'warn',
                                message:
                                    'Familia asociata acestui produs nu a fost gasita. Selectati sau creati o noua familie.',
                            });
                            return false;
                        }
                        this.familyCharacts = productFamily.characteristics;
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCategCharacts',
                            params: {
                                categ_id: val,
                            },
                        },
                    });
                    debugPromise('getCategCharacts', e);
                });
        },
        setCharactVal(index, val, charact) {
            if (val === '') delete this.product.charact[charact.charact_id][index - 1];
            else this.product.charact[charact.charact_id][index - 1] = val;
        },
        getProductDetails() {
            const params = {
                waitingId: this.waitingId,
            };

            getWaitingProduct(params)
                .then((res) => {
                    if (isEmpty(res.message)) {
                        this.sbMsg({
                            type: 'warn',
                            message: this.$t('products.pendingproduct.error-get-product') + '..',
                        });
                        this.$router.push('/produse/lista').catch(() => {});
                        return false;
                    }

                    if (res.message?.products_description != null) {
                        try {
                            res.message.products_description = atob(res.message.products_description);
                        } catch (e) {
                            console.log('Error decoding product description', e);
                        }
                    }
                    this.clone = res.message;
                    this.product = Object.assign({}, res.message);
                    if (typeof this.product.products_dimensions == 'undefined') this.product.products_dimensions = {};

                    this.product.status = String(this.product.status);
                    if (has(this.product, 'family_id') && parseInt(this.product.family_id) === 0)
                        this.product.family_id = '';
                    const hasSecondaryImages = has(this.product, 'image');
                    if (hasSecondaryImages) {
                        // let imgCnt = 0
                        for (let i = 0; i < 5; i++) {
                            if (typeof this.product.image[i] !== 'undefined') {
                                this.secondaryImages.push(this.product.image[i]);
                            } else {
                                this.secondaryImages.push({
                                    id: 0,
                                    url: '',
                                });
                            }
                        }
                    }
                    this.getFamilies();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getProducts',
                            params,
                        },
                    });
                    debugPromise('getProducts', e);
                });
        },
        addNewCharactVal() {
            if (this.newValueInput.length < 1) return false;
            const vm = this;
            this.allCharacteristics = this.allCharacteristics.map((char) => {
                if (parseInt(char.charact_id) === parseInt(vm.modalCharactId)) {
                    char.charact_value.push(vm.newValueInput);
                }
                return char;
            });
            this.dialogVisible = false;
            this.modalCharactId = '';
            this.modalCharactRestriction = '';
            this.newValueInput = '';
        },
        setPrimaryImage(index) {
            const tmp = this.product.products_image;
            this.product.products_image = this.secondaryImages[index]['url'];
            this.secondaryImages[index]['url'] = tmp;
        },
        validateData() {
            this.inputProblems = [];

            if (
                !(
                    this.product.categories_id !== 'undefined' &&
                    this.product.categories_id !== '' &&
                    this.product.categories_id !== 0
                )
            ) {
                this.inputProblems.push(this.$t('products.pendingproduct.error-category'));
            }
            if (this.product.products_name.length < 5) {
                this.inputProblems.push(this.$t('products.pendingproduct.error-name'));
            }
            // if (this.product.manufacturers_name.length < 2) {
            //     this.inputProblems.push(this.$t('products.pendingproduct.error-brand'));
            // }
            if (this.product.products_model.length < 3) {
                this.inputProblems.push(this.$t('products.pendingproduct.error-model'));
            }
            if (this.product.pretf < 1 || this.product.pretf.length < 1) {
                this.inputProblems.push(this.$t('products.pendingproduct.error-price'));
            }
            if (isNaN(this.product.stoc) || this.product.stoc.length < 1) {
                this.inputProblems.push(this.$t('products.pendingproduct.error-stock-2'));
            }

            let familyOk = false;
            let emptyFamilyCharacts = 0;

            let familyCharactsEmpty = false;
            for (const i in this.familyCharacts) {
                if (typeof this.familyCharacts[i].charact !== 'undefined' && this.familyCharacts[i].charact !== '') {
                    continue;
                } else emptyFamilyCharacts++;
            }

            if (this.familyCharacts.length === emptyFamilyCharacts || this.familyCharacts.length === 0) {
                familyCharactsEmpty = true;
            }

            switch (true) {
                case typeof this.product.family_id !== 'undefined' &&
                    this.product.family_id !== '' &&
                    (typeof this.product.family_name === 'undefined' ||
                        this.product.family_name === '' ||
                        this.familyCharacts.length < 1 ||
                        familyCharactsEmpty):
                    this.inputProblems.push(this.$t('products.pendingproduct.error-family'));
                    break;
                case typeof this.product.family_name !== 'undefined' &&
                    this.product.family_name !== '' &&
                    (typeof this.product.family_id === 'undefined' ||
                        this.product.family_id === '' ||
                        this.familyCharacts.length < 1 ||
                        familyCharactsEmpty):
                    this.inputProblems.push(this.$t('products.pendingproduct.error-family-2'));
                    break;

                case !familyCharactsEmpty &&
                    (typeof this.product.family_name === 'undefined' ||
                        this.product.family_name === '' ||
                        typeof this.product.family_id === 'undefined' ||
                        this.product.family_id === ''):
                    this.inputProblems.push(this.$t('products.pendingproduct.error-family-3'));

                default:
                    familyOk = true;
            }

            if (familyOk) {
                for (const i in this.familyCharacts) {
                    const currentFamilyCharact = this.familyCharacts[i];
                    if (currentFamilyCharact.charact == null || currentFamilyCharact.charact === '') {
                        this.familyCharacts.splice(i, 1);
                        continue;
                    }
                    let charName = this.allCharacteristics.find(
                        (c) => parseInt(c.charact_id) === parseInt(currentFamilyCharact.charact)
                    );
                    if (charName == null) {
                        this.familyCharacts.splice(i, 1);
                        continue;
                    }
                    charName = charName.charact_name;
                    if (
                        !has(this.product.charact, currentFamilyCharact.charact) ||
                        Object.keys(this.product.charact[currentFamilyCharact.charact]).length < 1
                    ) {
                        this.inputProblems.push(
                            this.$t('publish.add.error-charact') +
                                ' <strong>' +
                                charName +
                                '</strong> ' +
                                this.$t('publish.add.error-charact-2')
                        );
                    }
                }
            }

            // Disable required characts
            // for (const x in this.requiredCharacteristics) {
            //     if (
            //         isUndefined(this.product.charact[this.requiredCharacteristics[x]]) ||
            //         isEmpty(this.product.charact[this.requiredCharacteristics[x]]) ||
            //         (Array.isArray(this.product.charact[this.requiredCharacteristics[x]]) &&
            //             this.product.charact[this.requiredCharacteristics[x]].filter((i) => i.length > 0).length < 1)
            //     ) {
            //         this.inputProblems.push(
            //             this.$t('products.product.charact-single') +
            //                 ' <strong>' +
            //                 this.requiredCharacteristics[x] +
            //                 '</strong> ' +
            //                 this.$t('products.product.error-charact-3')
            //         );
            //     }
            // }
            // if (this.product.products_description < 5) {
            // 	this.inputProblems.push(
            // 		this.$t('products.product.error-desc'))
            // }
            // if (!has(this.product, 'products_description')) {
            // 	this.inputProblems.push(
            // 		this.$t('products.pendingproduct.error-desc'))
            // }
            if (!has(this.product, 'products_image') || this.product.products_image.length < 1) {
                this.inputProblems.push(this.$t('products.pendingproduct.error-main-image'));
            }
            // let mainImage = document.getElementById('mainImageId').querySelector('.el-upload__input').files
            // if (mainImage.length < 1) this.inputProblems.push('Imagine principala neselectata sau invalida(doar imagini PNG/JPG/GIF)')
            if (this.inputProblems.length > 0) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('products.pendingproduct.error-general-save'),
                });
                document.getElementById('problemsContainer').scrollIntoView();

                return false;
            }

            return true;
        },
        saveProduct() {
            if (!this.validateData()) return false;
            let characts = {};
            for (const x in this.product.charact) {
                let isAtleastOne = false;
                for (const y in this.product.charact[x]) {
                    if (size(this.product.charact[x][y]) && !isNull(this.product.charact[x][y])) isAtleastOne = true;
                }
                if (isAtleastOne) {
                    characts[x] = this.product.charact[x];
                }
            }
            characts = Object.assign({}, characts);
            let params = {
                _id: this.product._id,
                categories_id: this.product.categories_id,
                products_name: this.product.products_name,
                manufacturers_name: this.product.manufacturers_name,
                products_model: this.product.products_model,
                vendor_ext_id: this.product.vendor_ext_id != null ? this.product.vendor_ext_id : '',
                products_description: this.product.products_description,
                products_dimensions: this.product.products_dimensions,
                charact: characts,
                products_image: this.product.products_image,
                pretf: this.product.pretf,
                stoc: this.product.stoc,
                image: this.secondaryImages,
                // 'products_weight': this.product.type,
                pret_vechi: this.product.pret_vechi,
                status: this.product.status,
                garantie: this.product.garantie,
                garantie_pj: has(this.product, 'garantie_pj') ? this.product.garantie_pj : 0,
                procesare_in: this.product.procesare_in,
                real_weight: this.product.real_weight,
                refused: 0,
                virtual: has(this.product, 'virtual') ? this.product.virtual : 0,
            };

            if (has(this.product, 'family_id') && this.product.family_id !== '') {
                params.family_id = this.product.family_id;
                params.family_name = this.product.family_name;
                params.family_type = this.familyCharacts;
            }

            if (this.options.forceInsert) params.forceInsert = 1;
            if (this.secondaryImages.filter((item) => item.length > 0).length > 0) params.image = this.secondaryImages;
            params = setParams(params);
            this.inputProblems = [];
            updateWaitingProduct(params)
                .then((res) => {
                    if (!has(res, 'message') || !has(res, 'error') || parseInt(res.error) === 1) return false;
                    if (parseInt(res.message.success) === 0 && has(res.message, 'bad')) {
                        Message.error(this.$t('products.pendingproduct.some-values-invalid') + '!');
                        for (const i in res.message.bad) {
                            this.inputProblems.push(
                                this.$t('products.pendingproduct.column') +
                                    ': ' +
                                    res.message.bad[i].column +
                                    ' - ' +
                                    res.message.bad[i].msg
                            );
                        }
                        return false;
                    }
                    if (res.error > 0) {
                        this.sbMsg({
                            type: 'warn',
                            message: res.message,
                        });
                        return false;
                    }
                    this.$router.push('/produse/in-asteptare').catch(() => {});
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'updateWaitingProduct',
                            params,
                        },
                    });
                    debugPromise('updateProduct', e);
                });
        },
        addFamilyCharact() {
            this.familyCharacts.push({
                charact: '',
                mode: 1,
            });
        },
        removeFamilyCharact(index) {
            this.familyCharacts.splice(index, 1);
        },
        async categorySelected(categ) {
            if (typeof categ.categories_id !== 'undefined') {
                this.product.categories_id = categ.categories_id;
                if (parseInt(categ.categories_id) !== 0) {
                    this.getCategCharacts(categ.categories_id);
                } else {
                    this.allCharacteristics = [];
                    this.requiredCharacteristics = [];
                }
            }
        },
        getFamilies() {
            return new Promise((resolve, reject) => {
                getProductsFamilyForCategory({
                    categId: this.product.categories_id,
                })
                    .then((res) => {
                        this.families = res.message.families;
                        resolve();
                    })
                    .catch((e) => {
                        this.$reportToSentry(e, {
                            extra: {
                                fn: 'getProductsFamilyForCategory',
                                params: {
                                    categId: this.product.categories_id,
                                },
                            },
                        });
                        console.log(e);
                        resolve();
                    });
            });
        },
        onFamilySelect(id) {
            const selectedFamily = this.families.find((family) => parseInt(family.id) === parseInt(id));
            if (selectedFamily == null) {
                this.sbMsg({
                    type: 'warn',
                    message: 'Nu am gasit familia selectata.',
                });
                return false;
            }
            this.product.family_name = selectedFamily.name;
            if (selectedFamily.characteristics.length > 0) {
                this.familyCharacts = selectedFamily.characteristics.map((c) => {
                    return {
                        charact: parseInt(c.charact),
                        mode: parseInt(c.mode),
                    };
                });
            }
        },
        addNewFamily() {
            window.open('/produse/familii', '_blank');
        },
        removeProductFamily() {
            this.product.family_id = '';
            this.product.family_name = '';
        },
    },
    watch: {
        'product.family_id'(val) {
            if (val === '' || val === 0) {
                this.familyCharacts = [];
                return false;
            }
            const selectedFamily = this.families.find((family) => parseInt(family.id) === parseInt(val));
            if (selectedFamily == null) return false;
            this.familyCharacts = selectedFamily.characteristics;
        },
    },
    computed: {
        mainImageLink() {
            return has(this.product, 'products_image') ? this.product.products_image : '';
        },
        familyTypeOptions() {
            if (this.allCharacteristics.length < 1) return [];
            return this.allCharacteristics
                .filter((item) => {
                    return (
                        typeof item.charact_id !== 'undefined' &&
                        item.charact_id !== '' &&
                        typeof item.charact_name !== 'undefined' &&
                        item.charact_name !== ''
                    );
                })
                .map((item) => {
                    return {
                        value: parseInt(item.charact_id),
                        label: item.charact_name,
                    };
                });
        },
        familyCharactsComputed() {
            return this.familyCharacts.length;
        },
        characterLimits() {
            return this.$store.state.app.charactersLimit;
        },
    },
    created() {
        try {
            if (this.pid == null || this.pid === '') {
                throw new Error();
            }
            this.waitingId = this.pid.trim();
        } catch (e) {
            this.sbMsg({
                type: 'warn',
                message: this.$t('products.pendingproduct.error-model-url'),
            });
            this.$router
                .push({
                    name: 'Lista produse',
                })
                .catch(() => {});
            return false;
        }
        if (this.waitingId) this.getProductDetails();
    },
    beforeDestroy() {
        EventBus.$off();
    },
};
</script>

<style rel="stylesheet/scss" lang="scss">
@import './src/styles/modules/produse.scss';
</style>
<style>
.delinput input.el-input__inner {
    text-align: left !important;
}
</style>
<style scoped>
.delinput {
    width: 100%;
}
</style>
